import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import VideoPage from './pages/VideoPage';
import BlankPage from './pages/BlankPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Página principal */}
        <Route path="/" element={<BlankPage />} />
        
        {/* Página que retorna una card */}
        <Route path="/:totemId" element={<MainPage />} />
        
        {/* Segunda página que solo muestra el video de YouTube */}
        <Route path="/video/:cardId" element={<VideoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
