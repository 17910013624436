import React, { useState, useEffect } from 'react';

import Video from '../components/Video';
import Answer from  '../interfaces/answer.interface';

interface ContentProps {
  image: string;
  question: string;
  answers: Answer[];
  videoLink: string;
  message: string;
  backgroundColor: string;
}

const Content: React.FC<ContentProps> = ({ image, question, answers, videoLink, message, backgroundColor }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <div className="content">
      <img className="contentImage" src={`${image}`} alt="Patrimonio" />
      <div className="question">{question}</div>
      <div>
        {answers.map((answer, index) => (
          <button key={index} onClick={() => handleOptionClick(answer.text!)} style={{ backgroundColor: backgroundColor }}>
            {answer.text}
          </button>
        ))}
      </div>
      <div>
        {selectedOption && (
          <Video videoLink={videoLink} message={message} />
        )}
      </div>
    </div>
  );
};

export default Content;
