import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import Content from '../components/Content';
import Answer from '../interfaces/answer.interface';
import Route from '../interfaces/route.interface';
import './MainPage.css';

// Definir los tipos para los datos que recibimos del servicio REST

const MainPage: React.FC = () => {
  const { totemId } = useParams<{ totemId: string }>();
  console.log('************');
  console.log(totemId);
  console.log('************');

  const [data, setData] = useState<Route | null>(null);

  useEffect(() => {
    fetch(`https://api.rutas.civischile.cl/api/routes/${totemId}`)
      .then((response) => response.json())
      .then((data: Route) => setData(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  if (!data) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="MainPage">
      <Header image={data.headerImage} />
      <div className="main-container">
        <div className="sidebar" style={{ backgroundColor: data.backgroundColor }}> 
          <img className="sideBarImage" src="img/logo-organizaciones-estudiantiles.png" alt="Side Bar" />
        </div>
        <Content 
          image={data.contentImage}
          question={data.question} 
          answers={data.answers}
          videoLink={data.videoLink}
          message={data.message}
          backgroundColor={data.backgroundColor}
        />
      </div>
    </div>
  );
};

export default MainPage;
