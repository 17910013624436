import React from 'react';

interface VideoProps {
  videoLink: string;
  message: string;
}

const Video: React.FC<VideoProps> = ({ videoLink, message }) => {
  return (
    <div className="video-section">
      <iframe className='iframe-video'
        src={videoLink} 
        title="YouTube video player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
      ></iframe>
      <div className="message">{message}</div>
    </div>
  );
};

export default Video;
