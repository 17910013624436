import React from 'react';

interface HeaderProps {
  image: string;
}

const Header: React.FC<HeaderProps> = ({ image }) => {
  return (
    <div className="header">
      <img className="headerImage" src={`${image}`} alt="Header" />
    </div>
  );
};

export default Header;
