import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './VideoPage.css';

const VideoPage: React.FC = () => {
  const { cardId } = useParams<{ cardId: string }>();

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    fetch(`https://api.rutas.civischile.cl/api/routes/video/${cardId}`)
      .then((response) => response.json())
      .then((url: string) => setUrl(url))
      .catch((error) => console.error('Error:', error));
  }, []);

  if (!url) {
    return <div>Cargando...</div>;
  }

  const videoId = '7LGj2PaStm4'; // ID del video de YouTube
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-page">
      <iframe className="iframe-video"
        src={url} 
        title="YouTube video player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPage;